import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Search, Filter, ChevronDown, ChevronUp, Calendar, Clock, Download, FileText, MapPin, User } from 'lucide-react';
import axiosApi from '../api/axiosApi';
import NoReportsFound from './NoReportsFound';
import Loader from './Loader';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { format } from 'date-fns';

export default function AllReports() {
    const [reports, setReports] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showFilters, setShowFilters] = useState(false);
    const [filters, setFilters] = useState({
        vehicleNumber: '',
        dateFrom: '',
        dateTo: '',
        status: '',
        state: '',
        search: '',
    });
    const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);

    useEffect(() => {
        fetchAllReports();
    }, []);

    const fetchAllReports = async () => {
        try {
            const response = await axiosApi.get('/report/fetch/admin');
            setReports(response.data.data || []);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch reports');
            setLoading(false);
        }
    };

    const filteredReports = reports.filter(report => {
        const searchLower = filters.search.toLowerCase();
        const dateCreated = new Date(report.createdAt).toISOString().split('T')[0];

        const searchMatches =
            report.client_name?.toLowerCase().includes(searchLower) ||
            report.vehicle_number?.toLowerCase().includes(searchLower) ||
            report.report_generated_by_name?.toLowerCase().includes(searchLower) ||
            report.report_generated_by_state?.toLowerCase().includes(searchLower);

        return (
            searchMatches &&
            (filters.state === '' || report.report_generated_by_state?.toLowerCase() === filters.state.toLowerCase()) &&
            (filters.status === '' || report.status?.toLowerCase() === filters.status.toLowerCase()) &&
            (filters.dateFrom === '' || dateCreated >= filters.dateFrom) &&
            (filters.dateTo === '' || dateCreated <= filters.dateTo)
        );
    });

    const handleFilterChange = (name, value) => {
        setFilters(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const generatePDF = async (report) => {
        setIsGeneratingPDF(true);
        const doc = new jsPDF();
        const pageWidth = doc.internal.pageSize.width;
        const pageHeight = doc.internal.pageSize.height;

        // Colors
        const lightGreen = '#E8F5E9';
        const darkGreen = '#2E7D32';

        // Helper function to add section
        const addSection = (title, startY) => {
            doc.setFillColor(232, 245, 233); // lightGreen
            doc.setDrawColor(46, 125, 50); // darkGreen
            doc.roundedRect(10, startY, pageWidth - 20, 10, 2, 2, 'FD');
            doc.setTextColor(46, 125, 50);
            doc.setFontSize(16);
            doc.text(title, 15, startY + 7);
            return startY + 15;
        };

        const base64Image = report.logo;

        doc.text('Detox Analysis Report', pageWidth / 2, 20, { align: 'center' });


        // Add logo and header
        doc.addImage('/logo.png', 'PNG', 10, 10, 40, 15);
        doc.setFontSize(20);
        doc.setTextColor(46, 125, 50);

        doc.setFontSize(12);

        const imageWidth = 15;
        const imageHeight = 15;
        const xPosition = pageWidth - imageWidth - 15;
        const yPosition = 15;
        doc.addImage(base64Image, 'PNG', xPosition, yPosition, imageWidth, imageHeight);

        doc.text(`Report #${report.id}`, pageWidth - 15, 35, { align: 'right' });
        doc.text(`Status: ${report.status}`, pageWidth - 15, 40, { align: 'right' });
        doc.text(`Entry Date: ${format(new Date(report.createdAt), 'yyyy-MM-dd HH:mm')}`, pageWidth - 15, 45, { align: 'right' });
        doc.text(`Report Generated Date: ${format(new Date(), 'yyyy-MM-dd HH:mm')}`, pageWidth - 15, 50, { align: 'right' });

        // Add divider
        doc.setDrawColor(46, 125, 50);
        doc.setLineWidth(0.5);
        doc.line(10, 55, pageWidth - 10, 55);

        // Vehicle and Associate Details Section
        let yPos = 65;
        doc.setDrawColor(46, 125, 50);
        doc.setLineWidth(0.5);
        doc.rect(10, yPos, pageWidth - 20, 100);
        doc.line(pageWidth / 2, yPos, pageWidth / 2, yPos + 100);

        // Vehicle Details
        doc.setTextColor(46, 125, 50);
        doc.setFontSize(16);
        doc.text('Vehicle Details', 15, yPos + 10);

        const vehicleDetails = [
            ['Client Name', report.client_name],
            ['Vehicle Number', report.vehicle_number],
            ['Vehicle Reading', report.vehical_reading],
            ['Vehicle Type', report.vehical_type],
            ['Vehicle Company', report.vehicle_company_name],
            ['Fuel Type', report.vehicle_fuel],
        ];

        doc.autoTable({
            startY: yPos + 15,
            margin: { left: 15 },
            tableWidth: (pageWidth / 2) - 25,
            head: [['Parameter', 'Value']],
            body: vehicleDetails,
            theme: 'grid',
            headStyles: {
                fillColor: [232, 245, 233],
                textColor: [46, 125, 50],
                fontStyle: 'bold'
            },
            styles: {
                fontSize: 10
            }
        });

        // Associate Details
        doc.setTextColor(46, 125, 50);
        doc.text('Service Engineer Details', pageWidth / 2 + 5, yPos + 10);

        const associateDetails = [
            ['Name', report.report_generated_by_name],
            ['Email', report.report_generated_by_email],
            ['Mobile', report.report_generated_by_mobile],
            ['State', report.report_generated_by_state],
            ['City', report.report_generated_by_city],
        ];

        doc.autoTable({
            startY: yPos + 15,
            margin: { left: pageWidth / 2 + 5 },
            tableWidth: (pageWidth / 2) - 25,
            head: [['Parameter', 'Value']],
            body: associateDetails,
            theme: 'grid',
            headStyles: {
                fillColor: [232, 245, 233],
                textColor: [46, 125, 50],
                fontStyle: 'bold'
            },
            styles: {
                fontSize: 10
            }
        });

        // Parse inspection reports
        const preInspection = JSON.parse(report.pre_inspection_report || '{}');
        const postInspection = JSON.parse(report.inspection_report || '{}');

        // Scan Report Section
        yPos = 175;
        yPos = addSection('Scan Report', yPos);

        const scanReport = [
            ['Engine Load', preInspection.engine_load_before || 'N/A', postInspection.engine_load_after || 'N/A'],
            ['EGR Reading', preInspection.egr_reading_before || 'N/A', postInspection.egr_reading_after || 'N/A'],
            ['Mass Air Flow', preInspection.mass_air_flow_before || 'N/A', postInspection.mass_air_flow_after || 'N/A'],
            ['Air Fuel Mixture', preInspection.air_fuel_mixture_before || 'N/A', postInspection.air_fuel_mixture_after || 'N/A'],
            ['HP', preInspection.hp_before || 'N/A', postInspection.hp_after || 'N/A'],
            ['Throttle Position', preInspection.throttle_position_before || 'N/A', postInspection.throttle_position_after || 'N/A'],
        ];

        doc.autoTable({
            startY: yPos,
            head: [['Findings', 'Before', 'After']],
            body: scanReport,
            theme: 'grid',
            headStyles: {
                fillColor: [232, 245, 233],
                textColor: [46, 125, 50],
                fontStyle: 'bold'
            }
        });

        // Other Findings Section
        yPos = doc.lastAutoTable.finalY + 15;
        yPos = addSection('Other Findings', yPos);

        const otherFindings = [
            ['Alternator Output', preInspection.alternator_reading_before || 'N/A', postInspection.alternator_reading_after || 'N/A'],
            ['Thermostat Reading', preInspection.thermostat_reading_before || 'N/A', postInspection.thermostat_reading_after || 'N/A'],
            ['Fault Codes', preInspection.fault_codes_before || 'N/A', postInspection.fault_codes_after || 'N/A'],
        ];

        doc.autoTable({
            startY: yPos,
            head: [['Parameters', 'Before', 'After']],
            body: otherFindings,
            theme: 'grid',
            headStyles: {
                fillColor: [232, 245, 233],
                textColor: [46, 125, 50],
                fontStyle: 'bold'
            }
        });

        // Pollution Findings Section
        yPos = doc.lastAutoTable.finalY + 15;
        yPos = addSection('Pollution Findings', yPos);

        const pollutionFindings = [
            ['HSU', preInspection.hsu_before || 'N/A', postInspection.hsu_after || 'N/A'],
            ['LAMBDA', preInspection.lambda_before || 'N/A', postInspection.lambda_after || 'N/A'],
            ['CO', preInspection.co_before || 'N/A', postInspection.co_after || 'N/A'],
            ['CO2', preInspection.co2_before || 'N/A', postInspection.co2_after || 'N/A'],
            ['NOX', preInspection.nox_before || 'N/A', postInspection.nox_after || 'N/A'],
            ['O2', preInspection.o2_before || 'N/A', postInspection.o2_after || 'N/A'],
        ];

        doc.autoTable({
            startY: yPos,
            head: [['Parameters', 'Before', 'After']],
            body: pollutionFindings,
            theme: 'grid',
            headStyles: {
                fillColor: [232, 245, 233],
                textColor: [46, 125, 50],
                fontStyle: 'bold'
            }
        });

        // Mechanical Findings Section
        yPos = doc.lastAutoTable.finalY + 15;
        yPos = addSection('Mechanical Findings', yPos);

        const mechanicalFindings = [
            ['Engine Back Compression', preInspection.engineBackCompression || 'N/A'],
            ['Black Smoke', preInspection.blackSmoke || 'N/A'],
            ['White/Blue Smoke', preInspection.whiteBlueSmoke || 'N/A'],
            ['Air Filter Condition', preInspection.airFilterCondition || 'N/A'],
            ['Engine Oil', preInspection.engineOil || 'N/A'],
            ['Break Oil', preInspection.breakOil || 'N/A'],
            ['Steering Oil', preInspection.steeringOil || 'N/A'],
            ['MAF Sensor Clean', preInspection.mafSensorClean || 'N/A'],
        ];

        doc.autoTable({
            startY: yPos,
            head: [['Parameter', 'Status']],
            body: mechanicalFindings,
            theme: 'grid',
            headStyles: {
                fillColor: [232, 245, 233],
                textColor: [46, 125, 50],
                fontStyle: 'bold'
            }
        });

        // Expert Findings Section
        yPos = doc.lastAutoTable.finalY + 15;
        yPos = addSection('Expert Findings', yPos);

        const expertFindings = [
            ['Pickup Improve', postInspection.pickup_improve || 'N/A'],
            ['Smoothness Improve', postInspection.smoothness_improve || 'N/A'],
            ['Black Smoke Reduce', postInspection.black_smoke_reduce || 'N/A'],
            ['Vibration Improve', postInspection.vibration_improve || 'N/A'],
            ['Engine Noise Improve', postInspection.engine_noise_improve || 'N/A'],
            ['Acceleration Improve', postInspection.acceleration_improve || 'N/A'],
            ['Clutch Improve', postInspection.clutch_improve || 'N/A'],
            ['Knocking Improve', postInspection.knocking_improve || 'N/A'],
            ['Gear Shifting', postInspection.gear_shifting || 'N/A'],
            ['Breaks Working', postInspection.breaks_working || 'N/A'],
            ['Temperature', postInspection.temperature || 'N/A'],
        ];

        doc.autoTable({
            startY: yPos,
            head: [['Parameter', 'Status']],
            body: expertFindings,
            theme: 'grid',
            headStyles: {
                fillColor: [232, 245, 233],
                textColor: [46, 125, 50],
                fontStyle: 'bold'
            }
        });

        // Next Service Recommendations Section
        yPos = doc.lastAutoTable.finalY + 15;
        yPos = addSection('Next Service Recommendations', yPos);

        const serviceRecommendations = [
            ['Next Regular Service', 'After 5,000 KM or 3 months'],
            ['Oil Change', 'After 7,500 KM'],
            ['Air Filter Check', 'After 10,000 KM'],
            ['Brake Inspection', 'After 15,000 KM'],
        ];

        doc.autoTable({
            startY: yPos,
            head: [['Service Type', 'Recommended Date/Duration']],
            body: serviceRecommendations,
            theme: 'grid',
            headStyles: {
                fillColor: [232, 245, 233],
                textColor: [46, 125, 50],
                fontStyle: 'bold'
            }
        });

        // Additional Recommendations
        yPos = doc.lastAutoTable.finalY + 10;
        doc.setTextColor(46, 125, 50);
        doc.setFontSize(12);
        doc.text('Additional Recommendations:', 15, yPos);

        doc.setTextColor(0);
        doc.setFontSize(10);
        const recommendations = [
            '• Regular engine oil level checks',
            '• Maintain tire pressure as per specifications',
            '• Keep monitoring unusual sounds or vibrations',
            '• Follow manufacturer\'s maintenance schedule'
        ];
        recommendations.forEach((rec, index) => {
            doc.text(rec, 15, yPos + 5 + (index + 1) * 5);
        });

        // Signature Section
        yPos = doc.lastAutoTable.finalY + 50;

        // Left side - Report Date
        doc.setTextColor(46, 125, 50);
        doc.setFontSize(12);
        doc.text('Report Date:', 20, yPos);
        doc.setTextColor(0);
        doc.text(format(new Date(), 'dd/MM/yyyy'), 20, yPos + 15);

        // Right side - Signature
        doc.setTextColor(46, 125, 50);
        doc.text('Authorized Signature:', pageWidth - 70, yPos);

        // Signature line
        doc.setLineWidth(0.5);
        doc.line(pageWidth - 90, yPos + 30, pageWidth - 20, yPos + 30);

        // Name and designation
        const signatureTextX = pageWidth - 20;
        doc.setTextColor(0);
        doc.setFontSize(11);
        doc.text(report.report_generated_by_name || 'Authorized Person', signatureTextX, yPos + 40, { align: 'right' });
        doc.setTextColor(46, 125, 50);
        doc.text('Service Engineer', signatureTextX, yPos + 50, { align: 'right' });

        // Save the PDF
        doc.save(`Report_${report.id}.pdf`);
        setIsGeneratingPDF(false);
    };

    if (loading) return <Loader />;
    if (error) return <div className="text-center text-red-500 text-xl mt-8">Error: {error}</div>;

    return (
        <motion.div
            className="bg-white shadow-xl rounded-lg overflow-hidden"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
        >
            <div className="px-6 py-4 bg-gradient-to-r from-lime-600 to-green-600 text-white">
                <h3 className="text-2xl font-bold">All Reports</h3>
            </div>

            <div className="p-6">
                {/* Updated Search and Filter Section */}
                <div className="mb-8">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                        <div className="relative">
                            <Search className="absolute top-3 left-3 text-gray-400" size={20} />
                            <input
                                type="text"
                                placeholder="Search by vehicle number, client, generator name or state"
                                value={filters.search}
                                onChange={(e) => handleFilterChange('search', e.target.value)}
                                className="w-full pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-lime-500 transition duration-300"
                            />
                        </div>
                        <div className="relative">
                            <Clock className="absolute top-3 left-3 text-gray-400" size={20} />
                            <select
                                value={filters.status}
                                onChange={(e) => handleFilterChange('status', e.target.value)}
                                className="w-full pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-lime-500 transition duration-300 appearance-none"
                            >
                                <option value="">All Statuses</option>
                                <option value="completed">Completed</option>
                                <option value="in progress">In Progress</option>
                                <option value="draft">Draft</option>
                            </select>
                        </div>
                    </div>

                    <motion.button
                        onClick={() => setShowFilters(!showFilters)}
                        className="flex items-center text-lime-600 hover:text-lime-800 font-semibold"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                    >
                        <Filter size={20} className="mr-2" />
                        {showFilters ? 'Hide Filters' : 'Show Filters'}
                        {showFilters ? <ChevronUp size={20} className="ml-2" /> : <ChevronDown size={20} className="ml-2" />}
                    </motion.button>

                    <AnimatePresence>
                        {showFilters && (
                            <motion.div
                                className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4"
                                initial={{ opacity: 0, height: 0 }}
                                animate={{ opacity: 1, height: 'auto' }}
                                exit={{ opacity: 0, height: 0 }}
                            >
                                <div className="relative">
                                    <Calendar className="absolute top-3 left-3 text-gray-400" size={20} />
                                    <input
                                        type="date"
                                        value={filters.dateFrom}
                                        onChange={(e) => handleFilterChange('dateFrom', e.target.value)}
                                        className="w-full pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-lime-500 transition duration-300"
                                    />
                                </div>
                                <div className="relative">
                                    <Calendar className="absolute top-3 left-3 text-gray-400" size={20} />
                                    <input
                                        type="date"
                                        value={filters.dateTo}
                                        onChange={(e) => handleFilterChange('dateTo', e.target.value)}
                                        className="w-full pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-lime-500 transition duration-300"
                                    />
                                </div>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>

                {/* Reports List */}
                <div className="bg-white rounded-lg shadow-md overflow-hidden">
                    <div className="divide-y divide-gray-200">
                        {filteredReports.length > 0 ? (
                            filteredReports.map((report) => (
                                <motion.div
                                    key={report.id}
                                    className="flex items-center justify-between p-6 hover:bg-gray-50 transition duration-300"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                >
                                    <div className="flex items-start space-x-4">
                                        <FileText className="text-lime-600" size={32} />
                                        <div>
                                            <h5 className="font-semibold text-lg text-gray-800">{report.client_name}</h5>
                                            <p className="text-sm text-gray-600">Vehicle: {report.vehicle_number}</p>
                                            <p className="text-sm text-gray-600">
                                                Generated by: {report.report_generated_by_name || 'N/A'}
                                                {report.report_generated_by_state && ` (${report.report_generated_by_state})`}
                                            </p>
                                            <p className="text-sm text-gray-600">
                                                Date: {new Date(report.createdAt).toLocaleDateString()}
                                            </p>
                                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mt-2 ${report.status.toLowerCase() === 'completed' ? 'bg-green-100 text-green-800' :
                                                report.status.toLowerCase() === 'in progress' ? 'bg-yellow-100 text-yellow-800' :
                                                    'bg-gray-100 text-gray-800'
                                                }`}>
                                                {report.status}
                                            </span>
                                        </div>
                                    </div>
                                    {report.status.toLowerCase() === 'completed' && (
                                        <motion.button
                                            className="flex items-center text-lime-600 hover:text-lime-800 font-semibold"
                                            whileHover={{ scale: 1.05 }}
                                            whileTap={{ scale: 0.95 }}
                                            onClick={() => generatePDF(report)}
                                            disabled={isGeneratingPDF}
                                        >
                                            {isGeneratingPDF ? (
                                                <Loader />
                                            ) : (
                                                <>
                                                    <Download className="mr-2" size={20} />
                                                    Download PDF
                                                </>
                                            )}
                                        </motion.button>
                                    )}
                                </motion.div>
                            ))
                        ) : (
                            <NoReportsFound onRefresh={fetchAllReports} />
                        )}
                    </div>
                </div>
            </div>

            {/* Overlay Loader for PDF generation */}
            {isGeneratingPDF && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <Loader />
                </div>
            )}
        </motion.div>
    );
}
